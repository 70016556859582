import styled from '@emotion/styled';
import Container from '../../components/global/Container';
import TypographyStyle from '../../components/global/Typography.style';

export default styled(Container)`
  opacity: 1;
  transition: opacity 200ms linear;
  &.loading {
    opacity: 0;
  }
  & > .layout-box {
    & > ${TypographyStyle} > a {
      ${({ theme }) => theme.typography.underline};
      color: ${({ theme }) => theme.colors.pacificBlue};
      transition: color 200ms linear;
      &:hover {
        color: ${({ theme }) => theme.colors.bahamaBlue};
      }
      &:before {
        content: '';
        display: block;
      }
    }
    & > * {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
`;
