import React from 'react';
import * as Self from './module';
import ModuleStyle from './module.style';
import { registerModule } from '../../app/modules/reactModules';
import Heading from '../../components/global/Heading';
import RichText from '../../components/global/RichText';
import * as api from '../../app/helpers/api';
import getFeedbackSummary from '../../app/resources/feedbackSummary';

export const getServerSideProps = async ({ page }) => {
  const [feedback] = await Promise.all([getFeedbackSummary()]);
  const finalScore = feedback.statistics.find(
    (a) => a.question_type === 'final_score'
  );
  return {
    props: {
      loading: false,
      reviews: Number(finalScore.count),
    },
    maxage: 60,
    staleWhileRevalidate: 300,
  };
};

const ReviewsHeaderModule = ({
  loading = true,
  title,
  text,
  reviews,
  ...p
}) => {
  let content = title?.content;

  if (content && reviews) {
    content = content.replace('{reviews}', reviews);
  }
  return (
    <ModuleStyle
      {...p}
      wlLayout="centered"
      className={loading ? 'loading' : ''}
    >
      {!!title && <Heading title={{ ...title, content }} />}
      {!!text?.content && <RichText content={text.content} />}
    </ModuleStyle>
  );
};

export default ReviewsHeaderModule;

registerModule({ ReviewsHeader: Self });
